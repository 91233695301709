import React, { Component } from 'react';
import { connect } from 'react-redux';
import axios from "axios";
import * as Constants from './../store/index';
import FilterLink from "./../components/html/FilterLink";
import { Link  } from 'react-router-dom';
import GetShipping from '../components/html/GetShipping';
import Page from '../components/html/Page';
import GetPackageShipping from '../components/widget/GetPackageShipping';
import GetCost from '../components/html/GetCost';
import Filter from '../components/html/Filter';
import ShowCancelButton from '../components/html/ShowCancelButton';
import Alert from '../components/html/Alert';
import * as Util from './../data/General';
import CheckboxUnit from '../components/html/CheckboxUnit';
import Promo from '../components/html/Promo';

const TEXTDEFAULTSEARCH = "Cargando envios";

class ListShipping extends Component {

    state = {
        isSearch: false,
        isScroll: false,
        successText: "", 
        errorText: "",
        textSearch: TEXTDEFAULTSEARCH,
        shopsCustomerId: null,
        totalLocalSelected: 0
    };

    componentDidMount() {
        const { shippings, type, shop } = this.props;
        let filter = Constants.filterShippings;
        filter.status = type;
        this.loadData(filter);
        if (shippings && shippings.list && shippings.list.length > 0){
            document.getElementById('scroll-table').addEventListener("scroll", this.updateScroll);
        }
        this.setState({shopsCustomerId: Util.getListCustomerId(shop)}); 
    }
    updateScroll = () =>{
        const { isScroll } =this.state;
        if (document.getElementById('scroll-table').scrollLeft > 0){
            this.setState({ isScroll: true});
        }else if(isScroll){
            this.setState({ isScroll: false});
        }
    }
    loadData = (filter) => {
        this.setState({ isSearch: true});
        const { config } = this.props;
        filter.size = config["sizeshippings"];
        
        const url = Constants.URL_API+"shippings"+Constants.getFilter(filter);
        console.log(url);
        Constants.scrollToWindow();
        axios.get(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            return error;
          }).then((data) => {
            console.log(data);
            this.props.dispatch({
                type: 'CHANGE_LIST_SHIPPINGS',
                list: data,
                filter: filter
            });
            this.setState({ isSearch: false});
        }); 
    }
    
    changePage = (page) => {
        this.loadData(page);
    }
    
    setText = (e) => {
        const { shippings } = this.props;
        let value = e.target.value;
        shippings.filter.text = value;
        shippings.filter.from = 0;
        this.loadData(shippings.filter);
    }
    setSortOrder = (sort) => {
        const { shippings } = this.props;
        shippings.filter.from = 0;
        if (sort !== ""){
            let date = sort.split("*");
            shippings.filter.fromDate = date[0];
            shippings.filter.toDate = date[1];
            this.loadData(shippings.filter);
        }else{
            shippings.filter.fromDate = "";
            shippings.filter.toDate = "";
            this.loadData(shippings.filter);
        }
    }

    handleCarrier  = (sort) => {
        const { shippings } = this.props;
        shippings.filter.from = 0;
        shippings.filter.carrierId = sort;
        this.loadData(shippings.filter);
    }
    
    goTo = (from) => {
        const { shippings } = this.props;
        shippings.filter.from = from;
        this.loadData(shippings.filter);
    }
    handleChangePage = (page) => {

        const { shippings, config } = this.props;
        config["sizeshippings"] = page;
        
        this.props.dispatch({
            type: 'CHANGE_CONFIG',
            config: config
        });
        this.loadData(shippings.filter);

        return false;  
    }

    handleClickshipping = (shipping) => {
        this.props.dispatch({
            type: Constants.types.CHANGE_SHIPPING,
            shipping: shipping
        });
        return false;  
    }  

    setFulfillmentStatus = (status) => {
        const { shippings } = this.props;
        shippings.filter.status = status;
        shippings.filter.from = 0;
        this.loadData(shippings.filter);
    }

    setSortBy = (sort) => {
        const { shippings } = this.props;
        shippings.filter.from = 0;
        if (sort !== ""){
            let by = sort.split("*");
            shippings.filter.sortBy = by[0];
            shippings.filter.sortOrder = by[1];
            this.loadData(shippings.filter);
        }else{
            shippings.filter.sortBy = "createdAt";
            shippings.filter.sortOrder = "desc";
            this.loadData(shippings.filter);
        }
    }

    setDate = (sort) => {
        const { shippings } = this.props;
        shippings.filter.sortOrder = sort;
        shippings.filter.from = 0;
        this.loadData(shippings.filter);
    }

    cancelShipping = (shipping) => {
        const filter =  Constants.filterShippings;
        this.setState({ isSearch: true, textSearch: "Cancelando la orden "+shipping.orderName});
        const url = Constants.URL_API+"shippings/"+shipping.id;
        console.log(url);
        Constants.scrollToWindow();
        axios.delete(url, Constants.getHeaders()).then(response => response.data,error => {
            Constants.setErrors(error);
            if (error.response.status === 400) {
                this.setState({ errorText: Constants.getMessage(error.response.data.message), textSearch: TEXTDEFAULTSEARCH, isSearch: false});
                setTimeout(function(){
                    this.setState({ errorText: "" });
                }.bind(this), 20000);
            }
            return error;
          }).then((data) => {
              if (data.status === "CANCELED"){
                this.setState({ isSearch: false, successText: "La orden "+data.orderName+" fue cancelada con éxito!", textSearch: TEXTDEFAULTSEARCH});
                this.loadData(filter);
              }else{
                this.setState({ isSearch: false});
              }
              setTimeout(function(){
                this.setState({ successText: "" });
                }.bind(this), 20000);
        });
    }

    selected = (value, id) =>{
        const { shippings } = this.props;
        const { totalLocalSelected } = this.state; 
        let total = totalLocalSelected;
        shippings.list.map((orden)=>{
            if(orden.id === id){
                orden.selected = value;
                if (value){
                    total++;
                }else{
                    total--;
                }
            }
        });

        this.props.dispatch({
            type: 'CHANGE_LIST_SHIPPINGS',
            list: shippings.list,
            filter: shippings.filter
        });
        this.setState({totalLocalSelected: total});
    }

    print = () => {
        window.location.href = "/print/multi-order";
    }
    
    printAccuse = () => {
        window.location.href = "/print/accuse";
    }

    render() {
        const { shippings, shop, config } = this.props;
        const { isSearch, isScroll, successText, errorText, textSearch, shopsCustomerId, totalLocalSelected } = this.state;
        return (
            <div className="shippings">
                <Promo />
                <h1>
                    Envios 
                    <Link className="btn triciclo top-middle right" to={`/new-shipping`} >
                        <i className="icofont-truck-loaded"></i> Nuevo envio
                    </Link>
                    <button 
                        onClick={() => this.print()} 
                        className="btn triciclo top-middle right print" 
                        disabled={isSearch || totalLocalSelected > 0 ? false : true}
                        >
                        <i className="icofont-printer"></i>&nbsp;
                        Imprimir Pedido
                    </button>
                    <button 
                        onClick={() => this.printAccuse()} 
                        className="btn triciclo top-middle right print" 
                        disabled={isSearch || totalLocalSelected > 0 ? false : true}
                        >
                        <i className="icofont-printer"></i>&nbsp;
                        Imprimir acuse
                    </button>
                </h1>

                <div className="content-widget">
                    <ul role="tablist" className="filter-list">
                        <FilterLink 
                            varFilter={shippings.filter.status} 
                            onClick={this.setFulfillmentStatus} 
                            title="Todos"
                            filterSet=""
                        />
                        <FilterLink 
                            varFilter={shippings.filter.status} 
                            onClick={this.setFulfillmentStatus} 
                            title="En tránsito"
                            filterSet="IN_TRANSIT"
                        />
                        <FilterLink 
                            varFilter={shippings.filter.status} 
                            onClick={this.setFulfillmentStatus} 
                            title="Entregado"
                            filterSet="DELIVERED"
                        />
                        <FilterLink 
                            varFilter={shippings.filter.status} 
                            onClick={this.setFulfillmentStatus} 
                            title="Esperando recolección"
                            filterSet="PICKUP"
                        />
                        <FilterLink 
                            varFilter={shippings.filter.status} 
                            onClick={this.setFulfillmentStatus} 
                            title="Guía creada"
                            filterSet="PAYMENT_CONFIRMED"
                        />
                        <FilterLink 
                            varFilter={shippings.filter.status} 
                            onClick={this.setFulfillmentStatus} 
                            title="Cancelado"
                            filterSet="CANCELED"
                        />
                        <FilterLink 
                            varFilter={shippings.filter.status} 
                            onClick={this.setFulfillmentStatus} 
                            title="Incidente"
                            filterSet="INCIDENCE"
                        />
                    </ul>
                    <div className="filter-table">
                        <input type="search" value={shippings.filter.text} onChange={e => this.setText(e)}  placeholder="Buscar envios" />
                        <Filter handleSortDate={this.setSortOrder} handleCarrier={this.handleCarrier} timeInitial="" timeFinish="" />
                    </div>
                    <Alert errorText={errorText} successText={successText} />
                {shippings && shippings.list && shippings.list.length > 0 && (
                    <div className={`scroll-table ${isScroll ? "active" : ""}`} id="scroll-table">
                        <table>
                            {isSearch && ( 
                                <div className="center content-loading">
                                    <div className="loading">
                                        <div id="preloader">
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                            <span></span>
                                        </div> 
                                        <span>{textSearch}</span>
                                    </div>    
                                </div>
                            )}
                            <thead>
                                <tr>
                                    <th></th>
                                    <th>Orden</th>
                                    {shop.length > 1 && (<th>Tienda</th>)}
                                    <th>Destino</th>
                                    <th>Transportadora</th>
                                    <th>Estado</th>
                                    <th>Costo</th>
                                    <th>Acciones</th>
                                </tr>    
                            </thead>
                            <tbody>
                                {shippings.list.map((shipping) => (
                                    <tr key={shipping.id} title={Constants.status_title[shipping.status] ? Constants.status_title[shipping.status] : ""}>
                                        <td>
                                            <CheckboxUnit 
                                                edit={true} 
                                                id={shipping.id} 
                                                name="list" 
                                                selected={shipping.selected ? shipping.selected : false} 
                                                onBlur={this.selected}
                                            />
                                        </td>
                                        <td className="nowrap" onClick={() => this.handleClickshipping(shipping)}>
                                            {shipping.orderId && shipping.orderId !== "" ? (
                                                <React.Fragment>
                                                    <i className="icofont-grocery shopify"></i>&nbsp; 
                                                    <Link to={`/order-shipping/${shipping.orderId}`} >
                                                        {shipping.orderName}
                                                    </Link>
                                                </React.Fragment>    
                                            ) : (
                                                <React.Fragment>
                                                    <i className="icofont-map-pins"></i>&nbsp;
                                                    <Link to={`/shipping-tg`} >
                                                        Triciclo GO envio
                                                    </Link>
                                                </React.Fragment>    
                                            )}  
                                            <div className="destination orden-info">
                                                <i className="icofont-calendar"></i>  {Constants.getDate(shipping.createDate)}
                                            </div>
                                            <div className="destination orden-info">
                                                {shopsCustomerId && shopsCustomerId[shipping.customerId] && shopsCustomerId[shipping.customerId].shopifyDomain && (
                                                    <a href={`https://${shopsCustomerId[shipping.customerId].shopifyDomain}/admin/orders/${shipping.orderId}`}  className="ver-shopify-btn" target="_blank">
                                                        Ver en <i className="icofont-grocery"></i>
                                                    </a>
                                                )}
                                            </div>
                                        </td>
                                        {shop.length > 1 && shopsCustomerId && (
                                            <td>{shipping.customerId && shopsCustomerId[shipping.customerId] && shopsCustomerId[shipping.customerId].name}</td>
                                        )}
                                        <td>
                                            <GetShipping customer={shipping.destination} />
                                        </td>
                                        <td>
                                            {shipping.status !== "CANCELED" && (
                                                <GetPackageShipping shipping={shipping} />
                                            )}
                                            {shipping.cartaPorteStatus && (
                                                <div className="destination carrier">Carta Porte: {Util.CARTAPORTE[shipping.cartaPorteStatus]}</div>
                                            )}
                                        </td> 
                                        <td>
                                            <div title={Constants.status_title[shipping.status] ? Constants.status_title[shipping.status] : ""}>
                                            {Constants.status[shipping.status] ? (
                                                <span><i className={Constants.status_icon[shipping.status]}></i> {Constants.status[shipping.status]}</span>
                                            ) : <span><i className="icofont-clock-time"></i> Sin Estado</span> 
                                            }
                                            </div>
                                        </td>
                                        <td>
                                            <GetCost shipping={shipping} withText={false} />
                                        </td>
                                        {shipping.status !== "CANCELED" ? (
                                            <td onClick={() => this.handleClickshipping(shipping)}>
                                                <div>
                                                    {(shipping.carrierId === "LALAMOVE" || shipping.guideUrl) && (
                                                        <a href={shipping.carrierId !== "LALAMOVE" ?  shipping.guideUrl.replace("https://triciclogo-prod-guias.s3.amazonaws.com/", "https://shopify.triciclogo.com/getPDF/?pdf=") : shipping.trackerUrl} target="_blank">   
                                                            <i className="icofont-download"></i> Descargar
                                                        </a> 
                                                    )}
                                                </div>
                                                {/*<div>
                                                    <ShowCancelButton shipping = {shipping} cancelShippingCallback={this.cancelShipping}/>
                                                </div>*/}
                                                {(shipping.status === "PAYMENT_CONFIRMED" || shipping.status === "PICKUP") && (
                                                    <div>
                                                        <a href={"/new-pickup/"+shipping.id}>   
                                                            <i className="icofont-vehicle-delivery-van"></i> Nueva recolección
                                                        </a> 
                                                    </div>
                                                )}
                                            </td>
                                        ) : (
                                            <td></td>
                                        )}   
                                    </tr>
                                ))}
                            </tbody>
                        </table>
                    </div>
                )}
                <hr></hr>
                    <Page from={shippings.filter.from} size={config["sizeshippings"] ? config["sizeshippings"] : 0} elementSize={shippings.list.length} handleClick={this.goTo} handleChangePage={this.handleChangePage} />
                </div>
            </div>
            );
        }
    }
const mapStateToProps = (state) => ({
    shippings: state.shippings,
    today: state.today, 
    yesterday: state.yesterday, 
    lastWeek: state.lastWeek, 
    lastMonth: state.lastMonth,
    shop: state.shop,
    config: state.config
});

const mapDispatchToProps = dispatch => {
  return {
    dispatch
  }
}

// `connect` returns a new function that accepts the component to wrap:

// We normally do both in one step, like this:
export default connect(
  mapStateToProps,
  mapDispatchToProps
)(ListShipping);
